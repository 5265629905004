import {useCallback, useState} from 'react';
import {useApi, configApiRef} from '@backstage/core-plugin-api';
import {utilitiesApiRef} from '../utils/bitbucketUtilitiesApi';

export const useSetRepoSlug = (initialSlug: string) => {
  const [repoSlug, setRepoSlug] = useState(initialSlug);
  return {repoSlug, setRepoSlug};
};

export const useIsRepoSlugValid = () => {
  const configApi = useApi(configApiRef);
  const utilitiesApi = useApi(utilitiesApiRef);
  const isRepSlugValid = useCallback(
    async (repoSlug: string) => {
      const hasRepoName = !!repoSlug;
      const hasValidCharacters = /^[a-z-]+$/.test(repoSlug);
      if (!hasRepoName || !hasValidCharacters) {
        return false;
      }
      const exists = await utilitiesApi.repoExists(repoSlug, configApi.get('backend.baseUrl'));
      return !exists;
    },
    [utilitiesApi, configApi],
  );
  return isRepSlugValid;
};
