import React, {useState} from 'react';
import CodeMirror from '@uiw/react-codemirror';
import {langs} from '@uiw/codemirror-extensions-langs';
import {vscodeDark} from '@uiw/codemirror-theme-vscode';

export const CodeArea = () => {
  const [value, setValue] = useState(getSampleCatalogInfo());
  return (
    <CodeMirror
      value={value}
      height='100%'
      maxHeight='600px'
      onChange={setValue}
      theme={vscodeDark}
      editable={false}
      extensions={[langs.yaml()]}
      lang='yaml'
    />
  );
};

function getSampleCatalogInfo() {
  return `
#This is an example catalog-info.yaml to add to an existing repo you wish to register in Pariveda Backstage
#Trailing comments describe the use of all fields
#for option descriptions got to: https://parivedasolutions.atlassian.net/wiki/spaces/PI/pages/471859201/Backstage+Taxonomy
#Select a value for each field based on the complements
#When using a value from comments, do not include quotations
#DO NOT change any text preceding comments or not commented

apiVersion: backstage.io/v1alpha1
kind: Component #use 'Template' if adding a template
metadata:
  name: #name of the IP, dash separated words no spaces, use repo slug if unsure
  description: #description of the IP, cannot be empty
  links:
  - title: Website #optional, remove if there is no associated website
    url: #url to an associated website. this can be sharepoint or confluence
  - title: Slack Channel #optional, remove if there is no associated slack channel with this IP
    url: https://pariveda.slack.com/archives/CHANNELID #replace the channel id with your string
  #if there is no associated website or slack channel, remove links block
  annotations:
    backstage.io/techdocs-ref: dir:.
  tags:
  - #used for searching. this can be free form such as 'react' or 'java' or 'c#' or 'aws' or 'azure' etc
  - #must be lower case
  - #cannot be empty, remove entire block if there are no tags
spec:
  type: # 'Reference Application' or 'Reference Infrastructure' or 'Code Library' or 'Utility' or 'Template'
  owner: parivedasolutions #do not change
  lifecycle: # 'Development' or 'Production'
  system: # 'Internal' or 'Client'`;
}
