import {
  HomePageToolkit,
  HomePageCompanyLogo,
  ClockConfig,
  HeaderWorldClock,
} from '@backstage/plugin-home';
import {Content, Page, Header, TabbedCard, CardTab, InfoCard} from '@backstage/core-components';
import {HomePageSearchBar} from '@backstage/plugin-search';
import {SearchContextProvider} from '@backstage/plugin-search-react';
import {Grid, makeStyles} from '@material-ui/core';
import React from 'react';
import {useGetFullName} from '../../scaffolder/FullNameExtension/useGetFullName';
import LogoFullBlue from '../Root/LogoFullBlue';
import LogoIconBlueCircle from '../Root/LogoIconBlueCircle';
import {ModalButton} from './ModalButton';
import PostAddIcon from '@material-ui/icons/PostAdd';
import TSAPIcon from '../Root/TSAPIcon';
import {VersionInfoCardPage} from '@internal/plugin-version-info-card';

const clockConfigs: ClockConfig[] = [
  {
    label: 'New York ',
    timeZone: 'US/Eastern',
  },
  {
    label: 'Dallas',
    timeZone: 'US/Central',
  },
  {
    label: 'Denver',
    timeZone: 'US/Mountain',
  },
  {
    label: 'Seattle',
    timeZone: 'US/Pacific',
  },
];

const timeFormat: Intl.DateTimeFormatOptions = {
  formatMatcher: 'best fit',
  timeStyle: 'short',
};

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow:
      '1px 2px 6px 0px rgba(0,0,0,0.2), 0px 2px 1px 2px rgba(0,0,0,0.14), -1px 1px 0px 0px rgba(0,0,0,0.12)',
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
  logoContainer: {
    margin: theme.spacing(5, 0, 0, 0),
  },
  linkStyle: {
    color: '#1A57D6',
    fontSize: '18px',
    fontWeight: 600,
  },
  cards: {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1),
        width: theme.spacing(16),
        height: theme.spacing(16),
        boxShadow:
          '100px 3px 1px -2px red,0px 2px 2px 0px rgba(100,0,0,0.9),0px 1px 5px 0px rgba(0,0,0,0.12)',
      },
    },
  },
}));

const cardContentStyle = {height: 200};

const backstageTabStyle = {fontSize: '.8em', height: '100%'};

export const HomePage = () => {
  const classes = useStyles();
  return (
    <SearchContextProvider>
      <Page themeId='home'>
        <Header title={`Welcome, ${useGetFullName()}!`} pageTitleOverride='Home'>
          <HeaderWorldClock clockConfigs={clockConfigs} customTimeFormat={timeFormat} />
        </Header>
        <Content>
          <Grid container justifyContent='center' spacing={6}>
            <Grid
              container
              direction='column'
              justifyContent='flex-start'
              alignItems='center'
              spacing={0}
              xs={12}
              item
            >
              <HomePageCompanyLogo
                className={classes.logoContainer}
                logo={<LogoFullBlue height='75' width='auto' />}
              />
              <h3>Welcome to Pariveda Backstage! The home for fin IP</h3>
            </Grid>
            <Grid container item xs={12} justifyContent='center'>
              <HomePageSearchBar
                InputProps={{
                  classes: {root: classes.searchBarInput, notchedOutline: classes.searchBarOutline},
                }}
                placeholder='Search here for all IP in Bitbucket, Confluence and Backstage'
              />
            </Grid>
            <Grid container item xs={12} justifyContent='space-evenly'>
              <Grid container item xs={6} direction='row'>
                <Grid item xs={12}>
                  <InfoCard title='ABOUT BACKSTAGE' className={classes.cards}>
                    <div>
                      <p>
                        Backstage is an open-source platform for building a one-stop-shop developer
                        portal. Originally developed by Spotify, its platform capabilities include
                        software cataloging (tracking software assets, i.e. repos), templates (a
                        scaffold project/repo that can be used as a template for starting a new
                        project), documentation, and asset search which enables engineers across an
                        enterprise to discover, contribute, find, and collaborate on code and
                        technical assets.
                        <br /> Pariveda Backstage Vision As part of a TSAP initiative, Backstage is
                        intended to allow robust collaboration among fins. Code from clients, POCs,
                        tools or IP can be shared as examples, templates or scaffolding to help jump
                        start or provide inspiration for future projects. Pariveda's Backstage hopes
                        to be the entry point for future technical discussions and collaboration.
                      </p>
                    </div>
                  </InfoCard>
                </Grid>
                <Grid item xs={12}>
                  <InfoCard title='ADD EXISTING IP'>
                    <div>
                      <h4>To register a component in Pariveda Backstage follow these steps:</h4>
                      <ol>
                        <li>On the homepage, click "Create"</li>
                        <li>Click "Register a Component"</li>
                        <li>Enter the component URL ending in catalog-info.yaml</li>
                        <ul>
                          <li>URLs must be under the Pariveda Solutions bitbucket workspace</li>
                          <li>
                            If the URL cannot be found you may need to add
                            backstage-ip-capture@parivedasolutions.com as a user to your repo
                          </li>
                        </ul>
                        <li>Click "Analyze"</li>
                        <li>
                          If successful you will have the option to register another component.
                        </li>
                        <ul>
                          <li>
                            You can now view your component under the homepage when filtering kind
                            as "Component."
                          </li>
                        </ul>
                      </ol>
                    </div>
                  </InfoCard>
                </Grid>
                <Grid item xs={12}>
                  <InfoCard title='CREATE NEW IP'>
                    <div>
                      <h4>To create a component in Pariveda Backstage follow these steps:</h4>
                      <ol>
                        <li>On the homepage, click "Create"</li>
                        <li>Click The Component Template you wish to create</li>
                        <ul>
                          <li>
                            If you find no options, then templates have not been registered to
                            Backstage yet.
                          </li>
                        </ul>
                        <li>Enter the required fields for the component.</li>
                        <ul>
                          <li>These may differ based on the templates author.</li>
                        </ul>
                        <li>
                          Click through the prompts to create your new component based on the
                          template.
                        </li>
                        <ul>
                          <li>The new component will automatically registered in Backstage.</li>
                        </ul>
                      </ol>
                    </div>
                  </InfoCard>
                </Grid>
                <Grid item xs={12}>
                  <InfoCard title='REGISTER TEMPLATES'>
                    <div>
                      <h4>To register a component in Pariveda Backstage follow these steps:</h4>
                      <ol>
                        <li>On the homepage, in "Getting Started", click "Register Your Code"</li>
                        <li>Enter the component URL ending in template.yaml</li>
                        <ul>
                          <li>URLs must be under the Pariveda Solutions bitbucket workspace.</li>
                          <li>
                            If the URL cannot be found you may need to add
                            backstage-ip-capture@parivedasolutions.com as a user to your repo.
                          </li>
                        </ul>
                        <li>Click "Analyze"</li>
                        <li>
                          If successful you will have the option to register another component.
                        </li>
                        <ul>
                          <li>
                            You can now view your component under the homepage when filtering kind
                            as "Template".
                          </li>
                        </ul>
                      </ol>
                    </div>
                  </InfoCard>
                </Grid>
              </Grid>
              <Grid container item xs={6} direction='row' alignContent='flex-start'>
                <Grid item xs={12}>
                  <TabbedCard title='BACKSTAGE SUPPORT'>
                    <CardTab label='SUPPORT' style={backstageTabStyle}>
                      <div style={cardContentStyle}>
                        <h3>Slack Channels:</h3>
                        <ul>
                          <li>
                            <a
                              className={classes.linkStyle}
                              href='https://pariveda.slack.com/archives/C0738UQF5'
                              target='_blank'
                            >
                              Ask Internal IT
                            </a>
                          </li>
                          <li>
                            <a
                              className={classes.linkStyle}
                              href='https://pariveda.slack.com/archives/C04UC7VLAGN'
                              target='_blank'
                            >
                              Backstage Support
                            </a>
                          </li>
                          <li>
                            <a
                              className={classes.linkStyle}
                              href='https://pariveda.slack.com/archives/C4CBKFARJ'
                              target='_blank'
                            >
                              Fin SOS
                            </a>
                          </li>
                          <li>
                            <a
                              className={classes.linkStyle}
                              href='https://pariveda.slack.com/archives/C03SR4D0V3P'
                              target='_blank'
                            >
                              TSAP (Technology Solutions Architect Program)
                            </a>
                          </li>
                        </ul>
                      </div>
                    </CardTab>
                    <CardTab label='FEEDBACK' style={backstageTabStyle}>
                      <div style={cardContentStyle}>
                        You can submit feedback on the{' '}
                        <a
                          className={classes.linkStyle}
                          href='https://pariveda.slack.com/archives/C04UC7VLAGN'
                          target='_blank'
                        >
                          Backstage Support
                        </a>{' '}
                        channel
                      </div>
                    </CardTab>
                    <CardTab label='GET INVOLVED' style={backstageTabStyle}>
                      <div style={cardContentStyle}>
                        {' '}
                        <a
                          className={classes.linkStyle}
                          href='https://pariveda.slack.com/archives/C04T4U7NUMT'
                          target='_blank'
                        >
                          Join the Backstage Team!
                        </a>{' '}
                      </div>
                    </CardTab>
                  </TabbedCard>
                </Grid>
                <Grid item xs={12}>
                  <HomePageToolkit
                    title='Getting Started'
                    tools={[
                      {
                        url: 'create/templates/default/pariveda-ip-template',
                        label: 'Create Backstage IP',
                        icon: <LogoIconBlueCircle width='100%' />,
                      },
                      {
                        url: 'catalog-import',
                        label: 'Register Your Code',
                        icon: <LogoIconBlueCircle width='100%' />,
                      },
                      {
                        url: 'create',
                        label: 'Templates',
                        icon: <LogoIconBlueCircle width='100%' />,
                      },
                      {
                        url: 'create/edit',
                        label: 'New Template',
                        icon: <LogoIconBlueCircle width='100%' />,
                      },
                      {
                        url: 'https://pariveda.sharepoint.com/teams/TSAP',
                        label: 'TSAP Sharepoint',
                        icon: <TSAPIcon width='50px' />,
                      },
                    ]}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InfoCard title='Add Documentation'>
                    <Grid container>
                      <Grid item xs={12}>
                        <ModalButton
                          title='Create Sharepoint Page'
                          href='https://pariveda.sharepoint.com/teams/TSAP'
                          icon={<PostAddIcon />}
                        >
                          {
                            'Use this option to create a record in Sharepoint for new or existing IP. You will be directed to the Ip Library. Click New > Page > IP Capture Template and follow the instructions to log your IP.'
                          }
                        </ModalButton>
                      </Grid>
                      <Grid item xs={12}>
                        <ModalButton
                          title='Create Confluence Page'
                          href='https://parivedasolutions.atlassian.net/wiki/spaces/PI/overview'
                          icon={<PostAddIcon />}
                        >
                          Use this option to create a page in Confluence for new or existing IP. You
                          will be directed to the Ip Library space. Follow the instructions to add a
                          page.
                        </ModalButton>
                      </Grid>
                    </Grid>
                  </InfoCard>
                </Grid>

                <Grid item xs={12}>
                  <InfoCard title='FAQ'>
                    <div>
                      <ul>
                        <li>Question</li>
                        <ul>
                          <li>Answer</li>
                        </ul>
                      </ul>
                    </div>
                  </InfoCard>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <VersionInfoCardPage />
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
