import {ApiRef, createApiRef} from '@backstage/core-plugin-api';
import {Config} from '@backstage/config';

const requestOptions: RequestInit = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
};

export const bitbucketUtilitiesApi = (config: Config): UtilitiesApi => {
  const baseUrl = config.getString('backend.baseUrl');
  const repoExists = async (repo_slug: string) => {
    if (!repo_slug) {
      throw new Error('repo_slug is required');
    }

    try {
      const url = `${baseUrl}/api/utilities/repo_exists?${new URLSearchParams({
        repo_slug,
      })}`;
      const response = await fetch(url, requestOptions);
      if (response.status !== 200) {
        throw new Error(`${response.status} ${response.statusText}, ${await response.text()}`);
      }
      const data = (await response.json()) as {result: boolean};
      const doesRepoExist = data.result;
      return doesRepoExist;
    } catch (e) {
      throw new Error(`Unexpected response from validation, ${e}`);
    }
  };
  return {repoExists};
};

export type UtilitiesApi = {
  repoExists(repo_slug: string, baseUrl: string): Promise<boolean>;
};

export const utilitiesApiRef: ApiRef<UtilitiesApi> = createApiRef({
  id: 'utilitiesapi.custom',
});
